import { styled } from '@mui/material/styles';

const MyIcon = styled('div')(({ theme }) => ({
    ':hover':{
        opacity: 0.85,
      },
      ':focus':{
        opacity: 0.7,
      },
      ':disabled':{
        cursor: 'not-allowed',
        opacity: 0.35,
      },
      cursor: 'pointer',
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'center',
      fontSize: "calc(10px + 2vmin)",
      fontFamily: "title",
      wordSpacing: '1vh',
      borderRadius: 6,
      margin:'1vh',
      border: 'none',
}));

export default MyIcon;