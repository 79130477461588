import { ThemeProvider } from '@emotion/react';
import MyApp from 'MyComponents/MyApp';
import CreateSecret from 'pages/CreateSecret/CreateSecret';
import GetSecret from 'pages/GetSecret/GetSecret';
import { HomePage } from 'pages/Home/HomePage';
import NavBar from 'pages/NavBar/NavBar';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';
import { nsiTheme } from './ColorTheme';
function App() {

  const theme = nsiTheme; //color theme used

  return (
    <ThemeProvider theme={theme}>
      <MyApp>
        <Router>
          <NavBar theme={theme} />
          <Routes>
            <Route path="/*" element={<HomePage />} />
            <Route path="/secret/:url" element={<GetSecret />} />
            <Route path="/create" element={<CreateSecret theme={theme} />} />
          </Routes>
        </Router>
      </MyApp>
    </ThemeProvider>
  )
}

export default App;
