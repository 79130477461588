import React, { useState } from 'react';
import Modal from '../../../MyComponents/Dialog/Modal';
import MyInput from 'MyComponents/form/field/MyInput';
import MyButton from 'MyComponents/form/MyButton';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import { styled } from '@mui/material/styles';

const SharedIcon = styled('div')(({ theme }) => ({
    ':hover': {
        opacity: 0.85,
    },
    ':focus': {
        opacity: 0.7,
    },
    ':disabled': {
        cursor: 'not-allowed',
        opacity: 0.35,
    },
    gap: '5px',
    border: 'none',
    cursor: 'pointer',
    color: 'white',
    padding: '15px',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    fontSize: "calc(10px + 2vmin)",
    fontFamily: "title",
    wordSpacing: '1vh',
    borderRadius: 6,
    margin: '1vh',
}));

export default function SMSDialog(props: any) {

    const [number, setNumber] = useState('');

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        setNumber(event.currentTarget.value);
    }

    return (
        <>
            <Modal isOpen={props.open} handleClose={props.handleClose}>
                <div className='InfoField'>
                    <h2>Share by SMS</h2>
                    <label>Choose a phone number</label>
                    <MyInput
                        id="MyCopyLink"
                        value={number}
                        type="text"
                        onChange={handleChange}
                    />
                    <MyButton>
                        Send <i className="fa fa-paper-plane"></i>
                    </MyButton>
                </div>
            </Modal>
        </>
    )
}
