import { GoHome, Home } from 'MyComponents/GoHome';
import MyTitle from "MyComponents/MyTitle";
import { Link, useParams } from "react-router-dom";
import AccessSecret from './AccessSecret';

function GetSecret() {

  const params = useParams();

  return (
    <MyTitle>
      <Home>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <GoHome>
            {'>'} Home
          </GoHome>
        </Link>
      </Home>
      <AccessSecret url={params.url} />
    </MyTitle>
  );
}

export default GetSecret;
