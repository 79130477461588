
import MyAlert from 'MyComponents/MyAlert';
import MyBox from 'MyComponents/MyBox';
import { InformationForm, LogoGradient, MyFormTitle } from 'MyComponents/MyTitle';
import MyButton from 'MyComponents/form/MyButton';
import MyIcon from 'MyComponents/form/MyIcon';
import MyLabel from 'MyComponents/form/MyLabel';
import MyInfoField from 'MyComponents/form/field/MyInfoField';
import MyTextarea from 'MyComponents/form/field/MyTextarea';
import { InformationService } from 'pages/service/InformationService';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IInformation } from 'types/information';
import '../../App.css';
import MyInput from '../../MyComponents/form/field/MyInput';
import NotFound from './NotFound';

function AccessSecret(_props: any) {
  const { url } = _props;

  const [msg, setMsg] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [secretContent, setSecretContent] = useState<string>("");
  const [connectInfo, setConnectInfo] = useState<IInformation>({ secret: "", email: "" });
  const [connectionStatus, setConnexionStatus] = useState({ isAuthorized: false, attempt: false });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    setConnectInfo({ ...connectInfo, [name]: e.target.value });
    setMsg("");
  }

  const handleShowPassword = (e: { target: any; }) => {
    var x = document.getElementById("secret") as HTMLInputElement;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  useEffect(() => {
    InformationService.getInformation(url, connectInfo)
      .then((response: any) => {
        if (response.data.emailRequired === true) {
          setStatus("email");
        }
        if (response.data.passphraseRequired === true) {
          setStatus("passphrase");
        }
        if (response.data.emailRequired === true && response.data.passphraseRequired === true) {
          setStatus("email+passphrase");
        }
        if (response.data.emailRequired != true && response.data.passphraseRequired != true) {
          setConnexionStatus({ isAuthorized: true, attempt: true });
          setSecretContent(response.data.data);
        }
      })
      .catch((err: any) => {
        if (err?.statusCode === 404 || err?.response?.status === 404)
          setStatus("notFound");
      });
  }, []);

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    InformationService.getInformation(url, connectInfo)
      .then((response: any) => {
        setConnexionStatus({ isAuthorized: true, attempt: true });
        setSecretContent(response.data.data);
      })
      .catch((err: any) => {
        if (err?.statusCode === 404 || err?.response?.status === 404) {
          setStatus("notFound");
        }
        setConnexionStatus({ isAuthorized: false, attempt: true });
        setSecretContent("");
        if (err?.statusCode === 403 || err?.response?.status === 403) {
          setMsg("Incorrect Email and/or Passphrase");
        }
      });
  }

  if (status === "notFound") {
    return (
      <NotFound
        handleSubmit={handleSubmit}
      />
    )
  } else {
    return (
      <LogoGradient>
        <InformationForm>
          <MyFormTitle>Secret shared with you</MyFormTitle>
          <MyBox>
            <form className="App-Box" onSubmit={handleSubmit}>
              {((connectionStatus.isAuthorized === true) && (
                <>
                  <MyInfoField>
                    <MyLabel>Secret message</MyLabel>
                    <MyTextarea
                      id="SecretMessage"
                      placeholder="Your secret content..."
                      disabled={true}
                      value={secretContent}
                    />
                  </MyInfoField>
                  <Link to='/create'>
                    <MyButton>Share a secret<i className="fa fa-reply-all"></i></MyButton>
                  </Link>
                </>
              )) || (
                  <>
                    {status.search("email") >= 0 &&
                      <MyInfoField>
                        <MyLabel>Email</MyLabel>
                        <MyInput id="myEmail" name='email' value={connectInfo.email!} placeholder="Enter the recipient's email here..." type="text" autoComplete="off" onChange={handleChange} />
                      </MyInfoField>
                    }
                    {status.search("passphrase") >= 0 &&
                      <MyInfoField>
                        <MyLabel>Passphrase</MyLabel>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <MyInput
                            sx={{ width: "49.5vh", '@media only screen and (max-width: 700px)': { width: '35vh' } }}
                            name='secret'
                            value={connectInfo.secret!}
                            placeholder="A word or phrase that's difficult to guess"
                            type="password"
                            id="secret"
                            onChange={handleChange}
                          />
                          <MyIcon onClick={handleShowPassword}>
                            <i className="fa fa-eye-slash"></i>
                          </MyIcon>
                        </div>
                      </MyInfoField>
                    }

                    {connectionStatus.isAuthorized === false && connectionStatus.attempt === true &&
                      <MyAlert>{msg}</MyAlert>
                    }
                    <MyButton type="submit">Show secret message <i className="fa fa-reply-all"></i></MyButton>
                  </>
                )}
            </form>
          </MyBox>
        </InformationForm>
      </LogoGradient>
    );
  }
}

export default AccessSecret;