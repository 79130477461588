import axios from "axios";
import instance from "shared/keycloak/service/AxiosInstance";
import { IInformation, IInformationDto } from "types/information";

const API_URL: string = (process.env.REACT_APP_API_URL + "information/" as string);

const api = axios.create({
    baseURL: API_URL,
    headers: { "Content-Type": "application/json" },
});

export const InformationService = {
    getInformation: (url: string, connectInfo:IInformation) => api.post(API_URL + "url/" + url, connectInfo),

    createSecret: (information: IInformationDto) => instance.post(API_URL, information)
}