import { styled } from '@mui/material/styles';

const MyTitle = styled('div')(({ theme }) => ({
    minHeight: '12vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'sub-title',
}));

export const MyFormTitle = styled('div')(({ theme }) => ({
    backgroundColor:theme.palette.secondary.dark,
    color: 'white',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    fontSize: 'calc(10px + 2vmin)',
    borderRadius: '7px 7px 0px 0px',
    borderBottom: '1px solid black',
    minWidth: '30vh',
    width: '100vh',
    maxWidth: '110vh',
    padding: '1vh 3vh',
}));

export const InformationForm = styled('div')(({ theme }) => ({
    borderRadius: '10px',
    border: 'solid ' + theme.palette.primary.light,
}));

export const LogoGradient = styled('div')(({ theme }) => ({
    marginTop: '55px',
    borderRadius:'10px',
    padding: '0px 10px 10px 0px',
    position: 'relative',
    zIndex:'0',
    ':before':{
        content: '""',
        position: 'absolute',
        zIndex: '-2',
        inset: '-5px',
        transform: 'translate(10px, 8px)',
        background:'linear-gradient(245deg,rgba(142,209,219,1) 25%, rgba(106,39,111,1) 50%, rgba(239,77,78,1) 75%)',
        filter: 'blur(10px)'
    },
    ':after':{
        content: '""',
        position: 'absolute',
        zIndex: '-1',
        inset: 0,
        background:'inherit',
        border:'inherit',
        boxShadow:'inherit',
    },
}));

export default MyTitle;