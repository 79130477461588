import { styled } from '@mui/material/styles';
import { ReactNode } from "react";

interface ModalType {
    children?: ReactNode;
    isOpen: boolean;
    handleClose: () => void;
}

const Overlay = styled('div')(({ theme }) => ({
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: '0',
    background: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Box = styled('div')(({ theme }) => ({
    display: 'block',
    background: 'white',
    width: '50%',
    height: '50%',
    padding: '1rem',
    borderRadius: '1rem',
}));

export default function Modal(props: ModalType) {
    return (
        <>
            {props.isOpen && (
                <Overlay onClick={props.handleClose}>
                    <Box onClick={(e) => e.stopPropagation()}>
                        {props.children}
                    </Box>
                </Overlay>
            )}
        </>
    );
}