import { GoHome, Home, HomeCard } from "MyComponents/GoHome";
import { Link } from "react-router-dom";

export function HomePage(props: {}) {
    return (
        <Home>
            <HomeCard>
                <Link to='/create' style={{ textDecoration: 'none' }}>
                    <GoHome>
                        {'>'} Share secret
                    </GoHome>
                </Link>
            </HomeCard>
        </Home>
    )
}