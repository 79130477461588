import { styled } from '@mui/material/styles';

const MyAlert = styled('div')(({ theme }) => ({
    color: theme.palette.primary.dark,
    display: 'flex',
    opacity: 0.8,
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 'calc(10px + 1vmin)',
    marginTop:'2vh',
}));

export default MyAlert;