import axios from "axios";
import keycloak from "../keycloak";

export const getLocalAccessToken = () => {
    return keycloak.token;
}

export const getLocalRefreshToken = () => {
    return keycloak.refreshToken;
}


const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL_APP_2,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config: any) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        } else {
            keycloak.login();
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
