import LockIcon from '@mui/icons-material/Lock';
import MyNavBar from 'MyComponents/MyNavBar';

function NavBar(props: { theme: any; }) {
  const { theme } = props;
  return (
    <>
      <MyNavBar>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', textAlign: 'center' }}>
            <LockIcon sx={{ fontSize: '45px', color: theme.palette.secondary.light }} />
            {process.env.REACT_APP_NAME} - One Time Secret
          </div>
          <a href={process.env.REACT_APP_URL} target='_blank'>
            <img src={`data:image/png;base64,${process.env.REACT_APP_LOGO}`} style={{ width: '55px' }} alt='Logo' />
          </a>
        </div>
      </MyNavBar>
    </>
  );
}

export default NavBar;
