
export const Connecting = () => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 86.5px)', width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '80px', textAlign: 'center' }}>
                <>Connecting to Keycloak</>
            </div>
        </div>
    )
}