import { styled } from '@mui/material/styles';
import MyBox from 'MyComponents/MyBox';
import { InformationForm, LogoGradient, MyFormTitle } from 'MyComponents/MyTitle';
import MyLabel from 'MyComponents/form/MyLabel';
import MyInfoField from 'MyComponents/form/field/MyInfoField';
import { useState } from 'react';
import '../../App.css';
import MyInput from '../../MyComponents/form/field/MyInput';
import SMSDialog from './Dialog/SMSDialog';

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '10px',
  gap: '10px',
  justifyContent: 'center',
  textAlign: 'center',
  alignContent: 'center',
  alignItems: 'center'
}));

const SharedIcon = styled('div')(({ theme }) => ({
  ':hover': {
    opacity: 0.85,
  },
  ':active': {
    opacity: 0.7,
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.35,
  },
  backgroundColor: theme.palette.secondary.dark,
  cursor: 'pointer',
  color: theme.palette.secondary.light,
  fontSize: "calc(10px + 1vmin)",
  fontFamily: "title",
  wordSpacing: '1vh',
  border: 'none',
  borderRadius: '10px',
  minWidth: '28vh',
  padding: '10px 5px',
  margin: '2vh 10px',
  textAlign: 'center',
  '@media only screen and (max-width: 700px)': {
    width: '43vh',
  }
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const lifespanToString = (durationInMinutes: bigint): string => {
  return (durationInMinutes < 1440)
    ? ((durationInMinutes < 60)
      ? `${durationInMinutes} minutes`
      : `${Math.ceil(Number(durationInMinutes) / 60)} hours`
    )
    : `${Math.ceil(Number(durationInMinutes) / 1440)} days`;
}

function LinkToSecret(_props: any) {
  const { information } = _props;

  const handleCopyLink = (e: { target: any; }) => {
    var copyText = document.getElementById("MyCopyLink") as HTMLInputElement;
    navigator.clipboard.writeText(copyText.value)
      .then(() => {
        setError('');
        setMessage('Link copied !');
      })
      .catch(() => {
        setError('Error happend during copy, please try again or select the link  ');
        setMessage('');
      });
  }

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendLink = (type: String) => {
    if (type === 'email') {
      setMessage('');
      setError('Send by email is not implemented yet');
    } else {
      setMessage('');
      setError('Send by SMS is not implemented yet');
    }
  }

  return (
    <LogoGradient>
      <InformationForm>
        <MyFormTitle>Share this link to share your secret</MyFormTitle>
        <MyBox>
          <form onSubmit={() => { }}>
            <MyInfoField>
              <MyLabel>Link</MyLabel>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MyInput id="MyCopyLink" value={information.url} type="text" disabled={true} />
              </div>
            </MyInfoField>
            <Row>
              <SharedIcon onClick={() => handleSendLink('email')}>
                Share by Email <i className="fa fa-envelope-o "></i>
              </SharedIcon>
              <SharedIcon onClick={handleCopyLink}>
                Copy Link <i className="fa fa-clone"></i>
              </SharedIcon>
            </Row>
            <MyInfoField>
              <MyLabel style={{ fontSize: "calc(10px + 2vmin)", margin: '5vh 0vh' }}>This link will expire in {lifespanToString(information.lifespan)}</MyLabel>
              <MyLabel style={{ color: 'red' }}>{error}</MyLabel>
              <MyLabel style={{ color: 'green' }}>{message}</MyLabel>
            </MyInfoField>
          </form>
        </MyBox>

        <SMSDialog
          open={open}
          handleClose={handleClose}
        />
      </InformationForm>
    </LogoGradient>
  );
}

export default LinkToSecret;