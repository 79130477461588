import MyAlert from "MyComponents/MyAlert";
import MyBox from "MyComponents/MyBox";
import { InformationForm, LogoGradient, MyFormTitle } from "MyComponents/MyTitle";
import { FormEventHandler } from "react";

function NotFound(props: { handleSubmit: FormEventHandler<HTMLFormElement> }) {
    const { handleSubmit } = props;
    return (
        <LogoGradient>
            <InformationForm>
                <MyFormTitle>Secret shared with you</MyFormTitle>
                <MyBox>
                    <form className="App-Box" onSubmit={handleSubmit}>
                        <MyAlert>the secret has already been deleted</MyAlert>
                    </form>
                </MyBox>
            </InformationForm>
        </LogoGradient>
    )
}
export default NotFound;