import { styled } from '@mui/material/styles';

const MyTextarea = styled('textarea')(({ theme }) => ({
  color: theme.palette.primary.light,
  backgroundColor: theme.palette.primary.dark,
  fontFamily: 'Arial',
  margin: '1vh',
  padding: '1.5vh',
  borderRadius: 6,
  border: 'none',
  minWidth: '30vh',
  width: '55vh',
  minHeight: '3vh',
  height: '20px',
  maxWidth: '95vh',
  maxHeight: '15vh',
  '@media only screen and (max-width: 700px)': {
    width: '40vh',
    maxWidth: '50vh'
  }
}));

export default MyTextarea;