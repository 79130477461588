import { styled } from '@mui/material/styles';

const MyInput = styled('input')(({ theme }) => ({
  color: theme.palette.primary.light,
  backgroundColor: theme.palette.primary.dark,
  margin: '1vh',
  padding: '1.5vh',
  borderRadius: 6,
  border: 'none',
  minWidth: '30vh',
  width: '55vh',
  height: '3vh',
  '@media only screen and (max-width: 700px)': {
    width: '40vh',
  }
}));

export default MyInput;