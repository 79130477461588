import { styled } from '@mui/material/styles';

const MyBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    fontSize: 'calc(10px + 2vmin)',
    borderRadius: '0px 0px 7px 7px',
    minWidth: '30vh',
    width: '100vh',
    maxWidth: '110vh',
    padding: '1vh 3vh',
    marginTop: '0',
    '@media only screen and (max-width: 700px)': {
        width: '50vh',
    },
}));

export default MyBox;