import { styled } from '@mui/material/styles';

const MyNavBar = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'justify',
    fontSize: 'calc(12px + 3vmin)',
    fontFamily: 'title',
    fontWeight: '1300',
    padding: '2vh',
    height: '42px',
    border:'1px solid black'
}));

export default MyNavBar;