
import MyAlert from 'MyComponents/MyAlert';
import MyBox from 'MyComponents/MyBox';
import { InformationForm, LogoGradient, MyFormTitle } from 'MyComponents/MyTitle';
import IsRequired from 'MyComponents/form/IsRequired';
import MyIcon from 'MyComponents/form/MyIcon';
import MyLabel from 'MyComponents/form/MyLabel';
import MyInfoField from 'MyComponents/form/field/MyInfoField';
import { InformationService } from 'pages/service/InformationService';
import { SyntheticEvent, useEffect, useState } from 'react';
import { IInformation, IInformationDto } from 'types/information';
import '../../App.css';
import MyButton from '../../MyComponents/form/MyButton';
import MyInput from '../../MyComponents/form/field/MyInput';
import MySelect from '../../MyComponents/form/field/MySelect';
import MyTextarea from '../../MyComponents/form/field/MyTextarea';

function Informations(props: { setActiveStep: Function, setData: Function }) {

  const { setActiveStep, setData } = props;

  const [inputs, setInputs] = useState<IInformationDto>({
    secret: "",
    email: "",
    data: "",
    lifespan: 10080,
    emailRequired: false,
    passphraseRequired: false
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [msg, setMsg] = useState("");

  const handleShowPassword = (e: { target: any; }) => {
    var x = document.getElementById("myPassphrase") as HTMLInputElement;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const lifeTimeOption = [
    { label: "7 days", value: 10080 },
    { label: "3 days", value: 4320 },
    { label: "1 day", value: 1440 },
    { label: "12 hours", value: 720 },
    { label: "4 hours", value: 240 },
    { label: "1 hour", value: 60 },
    { label: "30 minutes", value: 30 },
    { label: "5 minutes", value: 5 }
  ];

  const handleChange = (e: { target: any; }) => {
    let data = document.getElementById("mySecret") as HTMLInputElement;
    let email = document.getElementById("myEmail") as HTMLInputElement;
    let secret = document.getElementById("myPassphrase") as HTMLInputElement;
    let lifetime = document.getElementById("myLifetime") as HTMLInputElement;
    if (email.value != inputs.email) {
      setMsg("");
    }
    setInputs({ secret: secret.value, email: email.value, data: data.value, lifespan: parseInt(lifetime.value), emailRequired: (email.value != "" ? true : false), passphraseRequired: (secret.value != "" ? true : false) });
  }

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    InformationService.createSecret(inputs)
      .then((response: any) => {
        let data: IInformation = {
          url: `${process.env.REACT_APP_DOMAIN}/secret/${response.data.url}`,
          lifespan: BigInt(response.data.lifespan),
        }
        setActiveStep(1);
        setData(data);
      })
      .catch(err => {
        if (err?.statusCode === 400 || err?.response?.status === 400) {
          setMsg("Email invalid");
        } else {
          console.log(err);
        }
      });
  }

  useEffect(() => {
    setIsDisabled(false);
    if (inputs.data === "") {
      setIsDisabled(true);
    }
  }, [inputs]);

  return (
    <LogoGradient>
      <InformationForm>
        <MyFormTitle>Share your Secret</MyFormTitle>
        <MyBox>
          <form onSubmit={handleSubmit}>
            <MyInfoField>
              <MyLabel>Secret message<IsRequired>*</IsRequired></MyLabel>
              <MyTextarea
                id="mySecret"
                name='secretMessage'
                placeholder="Enter your secret content here..."
                required
                onChange={handleChange}
              />
            </MyInfoField>

            <MyInfoField>
              <MyLabel>Email</MyLabel>
              <MyInput id="myEmail" name='email' placeholder="Enter the recipient's email here..." type="text" autoComplete="off" onChange={handleChange} />
            </MyInfoField>

            <MyInfoField>
              <MyLabel>Passphrase</MyLabel>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MyInput
                  sx={{ width: "49.5vh", '@media only screen and (max-width: 700px)': { width: '35vh' } }}
                  name='passphrase'
                  placeholder="A word or phrase that's difficult to guess"
                  type="password"
                  id="myPassphrase"
                  onChange={handleChange}
                />
                <MyIcon onClick={handleShowPassword}>
                  <i className="fa fa-eye-slash"></i>
                </MyIcon>
              </div>
            </MyInfoField>

            <MyInfoField>
              <MyLabel>Lifetime</MyLabel>
              <MySelect id="myLifetime" name='lifetime' onChange={handleChange}>
                {lifeTimeOption.map(option => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  )
                })}
              </MySelect>
            </MyInfoField>

            {msg != "" &&
              <MyAlert>
                {msg}
              </MyAlert>
            }

            <MyButton disabled={isDisabled} type="submit">
              Create secret <i className="fa fa-paper-plane"></i>
            </MyButton>
          </form>
        </MyBox>
      </InformationForm>
    </LogoGradient>
  );
}

export default Informations;
