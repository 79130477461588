import { createTheme } from '@mui/system';

//////////////////////////////   PALETTE   ///////////////////////////////////
//                                                                           //
//      primary:                                                             //
//         main          => main background                                  //
//         contrastText  => titles abrove box of form                        //
//         light         => text in field of form                            //
//         dark          => background of field                              //
//                                                                           //
//      secondary:                                                           //
//         main          => navbar background + button                       //
//         contrastText  => title of field in form box                       //
//         light         => background of form box                           //
//                                                                           //
//////////////////////////////////////////////////////////////////////////////


export const pastèque = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#644829',
      light: '#045710',
      dark: '#fff',
    },
    secondary: {
      main: '#97B106',
      contrastText: '#045710',
      light: '#F57781',
    },
  },
});

export const acide = createTheme({
  palette: {
    primary: {
      main: '#F9F0A4',
      contrastText: '#644829',
      light: '#000',
      dark: '#fff',
    },
    secondary: {
      main: '#516508',
      contrastText: '#644829',
      light: '#BECD86',
    },
  },
});

export const kawai = createTheme({
  palette: {
    primary: {
      main: '#E2BCF9',
      contrastText: '#9906ff',
      light: '#000',
      dark: '#fff',
    },
    secondary: {
      main: '#D85AEA',
      contrastText: '#9906ff',
      light: '#E3D2ED',
    },
  },
});

export const Royal = createTheme({
  palette: {
    primary: {
      main: '#5D0303',
      contrastText: '#D7A201',
      light: '#000',
      dark: '#fff',
    },
    secondary: {
      main: '#D7A201',
      contrastText: '#D7A201',
      light: '#460303',
    },
  },
});

export const methode = createTheme({
  palette: {
    primary: {
      main: '#DADDD8',
      contrastText: '#373836',
      light: '#000',
      dark: '#fff',
    },
    secondary: {
      main: '#6D735E',
      contrastText: '#373836',
      light: '#CDD2BF',
    },
  },
});

export const marin = createTheme({
  palette: {
    primary: {
      main: '#E0EBF8',
      contrastText: '#27374D',
      light: '#000',
      dark: '#fff',
    },
    secondary: {
      main: '#2F425D',
      contrastText: '#27374D',
      light: '#748BAA',
    },
  },
});

export const amazon = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#da0025',
      light: '#000',
      dark: '#fff',
    },
    secondary: {
      main: '#004526',
      contrastText: '#50514F',
      light: '#C0D1C9',
    },
  },
});

export const lacoste = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#da0025',
      light: '#000',
      dark: '#fff',
    },
    secondary: {
      main: '#004526',
      contrastText: '#50514F',
      light: '#C0D1C9',
    },
  },
});

export const demo3Theme = createTheme({
  palette: {
    primary: {
      main: '#0D1321',
      contrastText: '#fff',
      light: '#fff',
      dark: '#50514F',
    },
    secondary: {
      main: '#FF8552',
      contrastText: '#50514F',
      light: '#cecece',
    },
  },
});

export const demo2Theme = createTheme({
  palette: {
    primary: {
      main: '#50514F',
      contrastText: '#fff',
      light: '#fff',
      dark: '#50514F',
    },
    secondary: {
      main: '#CBD4C2',
      contrastText: '#50514F',
      light: '#9EA498',
    },
  },
});

export const demo1Theme = createTheme({
  palette: {
    primary: {
      main: '#DFE0E2', //
      contrastText: '#433E3F', //
      light: '#000',
      dark: '#fff', //
    },
    secondary: {
      main: '#18534F', //
      contrastText: '#DFE0E2', //
      light: '#705D56', //
    },
  },
});

export const customTheme = createTheme({
  palette: {
    primary: {
      main: '#282c34',
      contrastText: '#fff',
      light: '#fff',
      dark: '#282c34',
    },
    secondary: {
      main: '#f4b942',
      contrastText: '#000',
      light: '#d3d3d3',
    },
  },
});

export const amfieTheme = createTheme({
  palette: {
    primary: {
      main: '#f2f2f2',
      contrastText: '#000',
      light: '#000',
      dark: '#f2f2f2',
    },
    secondary: {
      main: '#003888',
      contrastText: '#000',
      light: '#d3d3d3',
    },
  },
});

export const nsiTheme = createTheme({
  palette: {
    primary: {
      main: '#0097c2',
      contrastText: '#f04b4c',
      light: '#000',
      dark: '#f2f2f2',
    },
    secondary: {
      main: '#0097c2',
      contrastText: '#000',
      light: '#d3d3d3',
      dark:'#007494',
    },
  },
});
