import { styled } from '@mui/material/styles';

export const Home = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
}));

export const GoHome = styled('div')(({ theme }) => ({
    display:'flex',
    color: 'white',
    fontSize: 'calc(10px + 1.5vmin)',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    marginTop: '15%'
}));

export const HomeCard = styled('div')(({ theme }) => ({
    marginLeft:'45%',
    marginTop:'10%'
}));
