import { styled } from '@mui/material/styles';

const MyButton = styled('button')(({ theme }) => ({
  ':hover': {
    opacity: 0.85,
  },
  ':focus': {
    opacity: 0.7,
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.35,
  },
  backgroundColor:theme.palette.secondary.dark,
  cursor: 'pointer',
  color: theme.palette.secondary.light,
  fontSize: "calc(10px + 2vmin)",
  fontFamily: "title",
  wordSpacing: '1vh',
  borderRadius: 6,
  border: 'none',
  minWidth: '35vh',
  padding:'15px',
  margin: '2vh 0vh',
  height: '9vh',
  '@media only screen and (max-width: 700px)': {
    width: '43vh',
  }
}));

export default MyButton;