import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Home } from 'MyComponents/GoHome';
import MyTitle from 'MyComponents/MyTitle';
import LinkToSecret from 'pages/CreateSecret/LinkToSecret';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import keycloak from 'shared/keycloak/keycloak';
import { Connecting } from 'shared/keycloak/page/Connecting';
import Informations from './Informations';

function CreateSecret(props: { theme: any; }) {

  const { theme } = props;

  const location = useLocation();

  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(null);

  return (
    <ReactKeycloakProvider
      LoadingComponent={<Connecting />}
      initOptions={{
        onLoad: 'login-required',
        pkceMethod: 'S256',
        checkLoginIframe: false,
      }}
      authClient={keycloak}
    >
      {activeStep == 0 &&
        <MyTitle>
          <Informations setActiveStep={setActiveStep} setData={setData} />
        </MyTitle>
      }
      {activeStep == 1 && data != null &&
        <MyTitle>
          <Home>
            {/* <Link to='/' style={{ textDecoration: 'none' }}>
              <GoHome>
                {'>'} Home
              </GoHome>
            </Link> */}
          </Home>
          <LinkToSecret information={data} />
        </MyTitle>
      }
    </ReactKeycloakProvider>
  );
}

export default CreateSecret;